import { useQuery } from '@tanstack/react-query';
import ProductsServices from 'services/Products/ProductsServices';

const useGetRosterFee = (eventId: string) => {
  const { data, error, isFetching } = useQuery(['get-uf-roster-fee'], () =>
    ProductsServices.getUfRosterFee(eventId)
  );

  return {
    ...data,
    data: data?.data ?? undefined,
    isFetching,
    error,
  };
};

export default useGetRosterFee;
