import React, { FC, MouseEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import UserType from 'models/User/UserTypeModel';

import { ApplicationState } from 'redux/store';

import { roleCheck } from 'util/RoleCheck';

import './header-menu.scss';

interface IHeaderMenuProps {
  empty?: boolean;
}

const HeaderMenu: FC<IHeaderMenuProps> = ({ empty }) => {
  const user = useSelector((state: ApplicationState) => state.currentUser);

  const handleClickToNewVault = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();

    window.location.href = `${window.location.protocol}//${window.location.host}/v3/home-page`;
  };

  const homeLink = useMemo(() => {
    if (!roleCheck(user.data, UserType.REFEREE, true)) {
      return '/home';
    }

    return '/v3/home-page';
  }, [user]);

  const logoutClasses = useMemo(() => {
    if (roleCheck(user.data, UserType.REFEREE, true)) {
      return 'offset-right';
    }

    return '';
  }, [user]);

  return (
    <div className='header-menu' style={{ display: empty ? 'block' : '' }}>
      <Link to={homeLink} className='link'>
        <div className='logo' />
      </Link>
      {!empty && (
        <>
          <div className='menu-top'>
            <ul>
              {!roleCheck(user.data, UserType.REFEREE, true) && (
                <li>
                  <Link to='/home' className='link'>
                    Home
                  </Link>
                </li>
              )}
              {!roleCheck(user.data, UserType.CLUB_DIRECTOR, true) &&
                !roleCheck(user.data, UserType.REFEREE, true) && (
                  <li>
                    <Link to='/my-club' className='link'>
                      My Club
                    </Link>
                  </li>
                )}
              {roleCheck(user.data, UserType.CLUB_DIRECTOR, true) &&
                !roleCheck(user.data, UserType.REFEREE, true) && (
                  <li>
                    <Link to='/club-director' className='link'>
                      Club Director
                    </Link>
                  </li>
                )}
              {roleCheck(user.data, [
                UserType.STAFF,
                UserType.TOURNAMENT_DIRECTOR,
              ]) && (
                  <li>
                    <Link to='/admin-panel/tournaments' className='link'>
                      My Tournaments
                  </Link>
                  </li>
                )}
              {roleCheck(user.data, UserType.STAFF) && (
                <li>
                  <Link to='/admin-panel' className='link'>
                    Admin Panel
                  </Link>
                </li>
              )}
              <li>
                <a
                  href='/v3/home-page'
                  className='link'
                  onClick={handleClickToNewVault}
                >
                  New Vault
                  </a>
              </li>
              {!roleCheck(user.data, UserType.REFEREE, true) && (
                <li>
                  <Link to='/ranking' className='link'>
                    Ranking
                  </Link>
                </li>
              )}
              {roleCheck(user.data, UserType.REFEREE, true) && (
                <li>
                  <Link to='/v3/events?tab=my-events' className='link'>
                    My Events
                  </Link>
                </li>
              )}
              <li className={logoutClasses}>
                <Link to='/v3/logout' className='link'>
                  logout
                </Link>
              </li>
            </ul>
          </div>
          {!roleCheck(user.data, UserType.REFEREE, true) && (
            <div className='manage-icons'>
              <NavLink to='/my-settings' activeClassName='active'>
                <div className='box-action-top icon-manage' />
              </NavLink>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
