import React, { FC, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import Keycloak from 'keycloak-js';

import UserType from 'models/User/UserTypeModel';

import { useProfile } from 'hooks/v3/profile/useProfile';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics/useGoogleAnalytics';

import UserSelection from 'pages/UserSelection/UserSelection.page';

import { getUserId } from 'util/UsersUtil';
import EventService from 'services/Event/EventService'
import { EventView } from 'admin/models/event/Event';
import LoadingSpinner from 'components/base/LoadingSpinner/LoadingSpinner';

interface IPrivateRouteProps {
  auth: Keycloak.KeycloakInstance | undefined;
  render: React.FC;
  path: string;
  exact: boolean;
  userSelectedNotRequired?: boolean;
}

const PrivateRoute: FC<IPrivateRouteProps> = ({
  auth,
  path,
  exact,
  userSelectedNotRequired,
  render,
}) => {
  useGoogleAnalytics();

  const { currentUser } = useProfile();

  const location = useLocation();
  const history = useHistory();
  const [event, setEvent] = useState<EventView | null>(null);
  const [hasTournamentAccess, setHasTournamentAccess] = useState<boolean>(true);
  const hasFetchedEvent = useRef(false); // Use ref to prevent re-renders
  const isMounted = useRef(true); // Track component mount state

  const getEventId = useMemo(() => {
    const match = location.pathname.match(/^\/admin-panel\/tournaments\/([a-f\d]{24})/);
    return match ? match[1] : null;
  }, [location.pathname]);



  useEffect(() => {
    isMounted.current = true; // Mark component as mounted
    return () => {
      isMounted.current = false; // Cleanup function to prevent state updates
    };
  }, []);


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false; // Cleanup to prevent updates on unmount
    };
  }, []);

  useEffect(() => {
    if (!getEventId || hasFetchedEvent.current || !currentUser.accountEmail) return;

    hasFetchedEvent.current = true; // Prevents re-fetching even after re-renders

    const fetchEventAccess = async () => {
      try {
        const response = await EventService.find(getEventId);

        if (isMounted.current && response.success && response.data) {
          setEvent(response.data);

          const accessGranted =
            String(currentUser.accountEmail).trim().toLowerCase() ===
            String(response.data.ownerEmail).trim().toLowerCase();


          setHasTournamentAccess(accessGranted);
        }
      } catch (error) {
        if (isMounted.current) {
          console.error('Error fetching event:', error);
          setHasTournamentAccess(false);
        }
      }
    };

    fetchEventAccess();
  }, [getEventId, currentUser.accountEmail]);



  const isV3Route = useMemo(() => {
    return location.pathname.includes('v3/') || location.pathname.includes('ranking');
  }, [location.pathname]);

  const isStaff = useMemo(() => {
    return (
      currentUser.type === UserType.STAFF ||
      currentUser.types?.includes(UserType.STAFF)
    );
  }, [currentUser]);

  const isReferee = useMemo(() => {
    return (
      currentUser.type === UserType.REFEREE ||
      currentUser.types?.includes(UserType.REFEREE)
    );
  }, [currentUser]);

  const cantAccess = !isStaff && !isReferee && hasTournamentAccess === false;


  useEffect(() => {
    if (auth && auth.isTokenExpired()) {
      auth.updateToken(5).then(() => {
        localStorage.setItem('kc_token', auth.token || '');
        localStorage.setItem('kc_refreshToken', auth.refreshToken || '');
      }).catch(() => {
        localStorage.setItem('kc_token', '');
        localStorage.setItem('kc_refreshToken', '');
        history.push('/v3/logout');
      });
    }
  }, [auth, history]);

  // **Handle Loading State Until Auth & Tournament Access Are Evaluated**
  if (auth === undefined || hasTournamentAccess === null) {
    return <LoadingSpinner />
  }

  if (auth?.authenticated) {
    if (!isV3Route) {
      const selectedUser = getUserId();

      if (
        (!userSelectedNotRequired && !selectedUser) ||
        (!userSelectedNotRequired && selectedUser === null)
      ) {
        return <UserSelection />;
      }

      if (cantAccess) {
        return (
          <Redirect
            to={{
              pathname: '/v3/home-page',
              state: { from: location },
            }}
          />
        );
      }
    }

    if (currentUser && currentUser.types?.length === 0) {
      return (
        <Redirect
          to={{
            pathname: '/v3/complete-registration',
          }}
        />
      );
    }
    return <Route path={path} exact={exact} component={render} />;
  }

  return (
    <Redirect
      to={{
        pathname: '/v3/login',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
