import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';

import { ApplicationState } from 'redux/store';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetRostersByClubId } from 'hooks/v3/clubs/useGetRostersByClubId/useGetRostersByClubId';

import Loading from 'components/base/Loading/Loading';

import { BodyL } from 'styles/v3/variables';

import { Filters } from '../Filters/Filters';
import { RosterDivision } from './components';

import * as S from './styles';

interface IRoastersForClubProps {
  tab: string;
}

export const RoastersForClub: FC<IRoastersForClubProps> = ({ tab }) => {
  const { clubId } = useParams<{ clubId: string }>();

  const [pagination, setPagination] = useState<{
    current: number;
    pageSize: number;
    total: number;
  }>({
    current: 0,
    pageSize: 10,
    total: 0,
  })

  const [searchValue, setSearchValue] = useState('');

  const { data: currentClub } = useGetClubById(clubId);

  const { data: rostersClub, isFetching } = useGetRostersByClubId({
    clubId,
    page: pagination.current,
    size: pagination.pageSize,
    open: tab === 'open',
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const rosterClubData = useMemo(() => {
    if (!rostersClub?.content.length || !currentClub) {
      return [];
    }

    return rostersClub.content
      .map((roster) => ({
        id: roster.id as string,
        eventName: roster.event?.name ?? '',
        eventLogo: roster.event?.logo ?? '',
        rosterName: roster.name ?? '',
        totalOfCoaches: roster.coaches.length,
        totalOfPlayers: roster.players.length,
        status: roster.status ?? 'PENDING',
        isLocked: (roster?.locked) ?? false,
      }))
      .filter((roster) =>
        !searchValue
          ? true
          : roster.eventName.toLowerCase().includes(searchValue.toLowerCase())
      );
  }, [selectedUserIds, searchValue, currentClub, rostersClub]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handlePageSizeChange = useCallback((_: number, size: number) => {
    setPagination((prevState) => ({
      ...prevState,
      current: 0,
      pageSize: size,
    }))
  }, []);

  const handleCurrentPageChange = useCallback((page: number) => {
    setPagination((prevState) => ({
      ...prevState,
      current: page - 1,
    }))
  }, []);

  useEffect(() => {
    if (rostersClub) {
      setPagination((prevState) => ({
        ...prevState,
        total: rostersClub.total,
      }))
    }
  }, [rostersClub]);

  return (
    <>
      <Filters handleChange={handleSearchChange} />
      {!isFetching && rosterClubData.length > 0 && (
        <>
          {rosterClubData.map((roster) => (
            <RosterDivision
              key={roster.id}
              id={roster.id}
              eventLogo={roster.eventLogo}
              eventName={roster.eventName}
              rosterName={roster.rosterName}
              isRosterLocked={roster.isLocked}
              totalOfCoaches={roster.totalOfCoaches}
              totalOfPlayers={roster.totalOfPlayers}
            />
          ))}
          {pagination.total > 10 && (
            <S.PaginationWrapper>
              <Pagination
                hideOnSinglePage={pagination.total > 10}
                showSizeChanger
                current={pagination.current + 1}
                pageSize={pagination.pageSize}
                total={pagination.total}
                pageSizeOptions={['10', '20', '30', '50']}
                onChange={handleCurrentPageChange}
                onShowSizeChange={handlePageSizeChange}
              />
            </S.PaginationWrapper>
          )}
        </>
      )}
      {!isFetching && !rosterClubData.length && (
        <S.WrapperNoItem>
          <BodyL>There are no rosters to view</BodyL>
        </S.WrapperNoItem>
      )}
      {isFetching && (
        <Loading isLocal />
      )}
    </>
  );
};
