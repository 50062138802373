import { AxiosRequestConfig } from 'axios';
import ServiceResponse from '../util/ServiceResponse';
import APIServices from '../util/ApiServices';
import { ConfigApi } from '../config.api';
import { ProductView, RosterFee } from './types';

class ProductsServices {
  public static async getB2bEventProducts(): Promise<ServiceResponse<any>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getPublicConfig(),
      url: 'products/b2b-events',
      method: 'GET',
    };
    return APIServices.request<any>(axiosConfig);
  }

  public static async getProductById(
    id?: string
  ): Promise<ServiceResponse<ProductView | undefined>> {
    if (!id) {
      return ServiceResponse.success<ProductView | undefined>(undefined, '');
    }

    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `products/${id}`,
      method: 'GET',
    };

    return APIServices.request<ProductView>(axiosConfig);
  }

  public static async getUfRosterFee(eventId: string): Promise<ServiceResponse<RosterFee>> {
    const axiosConfig: AxiosRequestConfig = {
      ...ConfigApi.getNoAccountConfig(),
      url: `/products/uf-roster-fee/${eventId}`,
      method: 'GET',
    };

    return APIServices.request(axiosConfig);
  }
}

export default ProductsServices;
