import React, { FC, useEffect, useState } from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import EventService from 'services/Event/EventService';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';

import Button from 'components/uielements/Button/Button';

import ConvertUtil from 'util/ConvertUtil';
import { roleCheck } from 'util/RoleCheck';

import { EventView } from '../../models/event/Event';

const TournamentsSidemenu: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const history = useHistory();

  const user = useSelector((state: ApplicationState) => state.currentUser);

  const [tournaments, setTornaments] = useState<EventView[]>([]);

  useEffect(() => {
    EventService.list(
      new URLSearchParams({
        size: '100',
      })
    ).then((res) => {
      if (res.success) {
        setTornaments(
          res.data.content.filter(
            (event) =>
              event.eventType === 'TOURNAMENT' || event.eventType === 'CAMP'
          )
        );

        if (!res.data.content.find((e) => e.id === eventId)) {
          history.push('/admin-panel/tournaments');
        }
      }
    });
  }, [history, eventId]);

  useEffect(() => {
    const subpage = history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );

    document
      ?.getElementById(subpage)
      ?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, [history.location]);

  return (
    <Col md={24} lg={6}>
      <MenuContainer>
        {eventId && (
          <div className='tournament-selection-box'>
            <div className='wrapper-box'>
              <div className='logo'>
                <img
                  src={ConvertUtil.getMinioUrl(
                    tournaments.find((tournament) => tournament.id === eventId)
                      ?.logo
                  )}
                  style={{ width: '40px' }}
                  alt=''
                />
              </div>
              <h3>
                {
                  tournaments.find((tournament) => tournament.id === eventId)
                    ?.name
                }
              </h3>
            </div>
            {roleCheck(user.data, UserType.STAFF) && (
              <div
                className='bold text-right text-grey-light'
                style={{ margin: '10px 0', fontSize: '12px' }}
              >
                <Link className='link' to='/admin-panel/tournaments'>
                  Select a different Tournament
                </Link>
              </div>
            )}
          </div>
        )}
        <div className='submenu styled-scrollbar'>
          {!roleCheck(user.data, UserType.REFEREE, true) && (
            <>
              <NavLink
                id='team-applications'
                to={`/admin-panel/tournaments/${eventId}/team-applications`}
                exact
                activeClassName='active'
              >
                <Button>Team Applications</Button>
              </NavLink>
              <NavLink
                id='rosters'
                to={`/admin-panel/tournaments/${eventId}/rosters`}
                activeClassName='active'
              >
                <Button>Rosters</Button>
              </NavLink>
              <NavLink
                id='game-creation'
                to={`/admin-panel/tournaments/${eventId}/game-creation`}
                activeClassName='active'
              >
                <Button>Game Creation</Button>
              </NavLink>
            </>
          )}
          <NavLink
            id='games'
            to={`/admin-panel/tournaments/${eventId}/games`}
            activeClassName='active'
          >
            <Button>Games</Button>
          </NavLink>
        </div>
      </MenuContainer>
    </Col>
  );
};

const MenuContainer = styled.div`
  @media (max-width: 992px) {
    .submenu {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      overflow-x: auto;
      margin: 10px;
      grid-gap: 10px;
      padding-bottom: 10px;
      a button {
        font-size: 9px;
        padding: 15px 10px;
        margin: 0;
        height: 120px;
        min-width: 50px;
        width: 120px;
      }
    }
  }

  .tournament-selection-box {
    .wrapper-box {
      display: flex;
      align-items: center;
      .logo {
        padding: 15px;
        img {
          @media (max-width: 992px) {
            display: none;
          }
        }
      }
    }
  }
`;

export default TournamentsSidemenu;
