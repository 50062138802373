import Keycloak, { KeycloakInstance } from 'keycloak-js';

export default class KeycloakUtil {
  public static getInstance(clientId = 'frontend-b2b'): KeycloakInstance {
    return Keycloak({
      url:
        process.env.REACT_APP_BASE_KEYCLOAK_ENDPOINT ||
        'http://localhost:8080/auth/',
      realm: 'vault',
      clientId,
    });
  }
}
